import { Adobe } from './Adobe/adobe';
import './App.css';
import { Form } from './Form/form';


function App() {
  return (
    <>
      <Adobe />

      <Form />

    </>
  );
};

export default App;